import { useTemplateStore } from '~/store/template';
import { defineStore } from 'pinia'
import { useEventStore } from './event'
import { usePreweddingStore } from './prewedding'
import { useSettingControlStore } from './settingControl'
import { useSlideStore } from './slide'
import { useWishStore } from './wish'
import { usePromptpayStore } from './promptpay'

export const useAppStateStore = defineStore({
  id: 'appState',
  state: () => {
    return {
      loading: null,
      firstLoaded : false, //check first load เช็กว่าโหลดเรียบร้อยแล้วรึยัง
      isReady : false, //check cannot play go directly to play page
      backgroundColor: '#0000000'
    }
  },
  actions: {
    async init(eventId: string) {
      const eventStore = useEventStore()
      const preweddingStore = usePreweddingStore()
      const wishStore = useWishStore()
      const templateStore = useTemplateStore()
      const promptpayStore = usePromptpayStore()

      await eventStore.main(eventId)

      const promise_1 = preweddingStore.main()
      const promise_2 = wishStore.main()
      const promise_3 = promptpayStore.main()
      
      await Promise.all([promise_1, promise_2, promise_3])
      await templateStore.main()


      this.firstLoaded = true
    },
    setBackgroundColor(background: string) {

      if(!background) {
        this.backgroundColor = '#000000'
        return 
      }

      this.backgroundColor = `#${background}`
    },
    play() {
      const slideStore = useSlideStore()
      const settingControlStore = useSettingControlStore()

      settingControlStore.main()
      slideStore.initWebWorkerMessage()
      slideStore.main()
    //  slideStore.watchIndexAndViewMode()
    },

    exit() {
      const eventStore = useEventStore()
      const slideStore = useSlideStore()

      const $route = useRoute()

      const eventId = eventStore.eventId
      const host = window.location.origin
      slideStore.playMode = ''

      const isCustomMode = $route.query.mode === 'custom' ||  $route.query.mode === 'wish-first'
      const isCustomQuery = $route.query.custom

      if(isCustomMode || isCustomQuery === "true") {
        window.location.href = host + `?q=${eventId}&custom=true`
        return 
      }

      window.location.href = host + `?q=${eventId}`
    }

  },
  getters: {}
})