import { defineStore } from 'pinia'
import { db } from '~/service/firebase_config'

import { collection, query, onSnapshot, where, doc, getDoc, getDocs } from 'firebase/firestore'

import _forEach from 'lodash/forEach.js'
import _map from 'lodash/map.js'
import _concat from 'lodash/concat.js'
import _uniqBy from 'lodash/uniqBy.js'
import _unique from 'lodash/uniq.js'
import _find from 'lodash/find.js'
import _take from 'lodash/take.js'
import _ from 'lodash'
import { useEventStore } from './event'
import { useWishStore } from './wish'

import { useTemplate } from '../composables/useTemplate'
import { useAppStateStore } from './appState'


export const useTemplateStore = defineStore({
  id: 'template',
  state: () => {
    return {
      templateUsedIds: [] as Array<string>, //[ $templateId... ]
      templateList: [],
      prefetchStage: null,
      loadTemplateId: {},

      templateIdRendered: {},
      docId: "anyId"
    }
  },
  actions: {
    async main() {

      return new Promise(async (resolve) => {
        // await this.realTimeFetchTemplates()
        await this.fetchAllTemplates()
        resolve(true)
      })
    },

    fetchAllTemplates() {

      const p = new Promise(async (resolve) => {
        const querySnapshot = await getDocs(collection(db, 'templates'))
        const tmpList = []
        querySnapshot.forEach((doc) => {
          tmpList.push(doc.data())
        })

        const filtered = _.filter(tmpList, t => t?.version === '3')
        this.templateList = filtered
        resolve(true)
      })

      return p
    },

    // realTimeFetchTemplates() {
    //   const wishStore = useWishStore()
    //   const wishes = wishStore.allWishDatabase

    //   this.templateUsedIds = getUniqTemplateIdList(this.templateUsedIds, wishes)

    //   const p = new Promise(async resolve => {
    //     const tmpList = []

    //     for (let index = 0; index < this.templateUsedIds.length; index++) {
    //       const templateId = this.templateUsedIds[index];

    //       const docRef = doc(db, 'templates', templateId)
    //       const response = await getDoc(docRef)

    //       if (response.exists()) {
    //         const data = response.data()
    //         tmpList.push(data)
    //       }
    //     }

    //     const mergeList = _concat(this.templateList, tmpList)
    //     const uniqList = _uniqBy(mergeList, 'templateId')
    //     this.templateList = uniqList
    //     setTimeout(() => {
    //     }, 100)
    //     resolve(true)
    //   })

    //   return p
    // },

    //---------------------------
    async prefetchImage(url: string) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
          resolve(true)
        }
        img.crossOrigin = "Anonymous"
        img.src = url
      })
    },



    async preFetchWish(wishData: any, callback: any) {


      const templateId = wishData.templateId
      const templateData = _find(this.templateList, t => t.templateId === templateId)
      if (!templateData) {
        return
      }
      let promiseList = []
      if (this.templateIdRendered[templateId]) {
        return
      }

      // let els = _.flatMapDeep(templateData.elements, (element) => {
      //   // กระจาย frame elements ออกมา 
      //   return [element, element.elements]
      // })
      let els = []

      // for bg background
      const backgroundCfId = templateData?.elements?.background?.cfId
      els.push({
        type: 'IMAGE',
        url: `https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/${backgroundCfId}/present`
      })
      // els = _.filter(els, (el) => el?.type.toUpperCase() === 'IMAGE')


      _.forEach(els, (el) => {
        let url = el.url
        console.log(url)
        url = url.replace('original', 'present')

        promiseList.push(this.prefetchImage(url))
      })

      Promise.all(promiseList).then(() => {

        this.templateIdRendered[templateId] = true
        callback && callback()
      }).catch((e) => {

        console.error(e)
      })


    },

    //---------------------------

    render(wishData: any, elementId = 'container') {
      const appStateStore = useAppStateStore()
      const backgroundColor = appStateStore.backgroundColor || '#000000'
      const p = new Promise(async (resolve) => {
        const templateId = wishData.templateId
        const templateData = _find(this.templateList, t => t.templateId === templateId)

        if (!templateData) {
          return
        }
        _.delay(async () => {
          let result = null
          if(wishData?.version === 'v3') {
            result = await useRenderTemplateV3({
              container: elementId,
              template: templateData,
              wishData: wishData,
              variant : 'present',
              loadingOptions: {
                background : backgroundColor,
                text : ' '
              },
            })
          } else {
            result = await useRenderTemplateV1({
              container: elementId,
              template: templateData,
              wishData: wishData,
              variant : 'present',
              loadingOptions: {
                background : backgroundColor,
                text : ' '
              },
            })
          }
    
          // return result?.stage || null
          resolve(result?.stage)

          // const renderInstance = await useTemplate(elementId, templateData, wishData, { setting: null })
          // await renderInstance.createStudio(false)
          // resolve(renderInstance.stage)
        } , 5)

      })

      return p
    },
  },
  getters: {
    getTemplateData() {
      return (templateId: string) => {
        return _find(this.templateList, t => t.templateId === templateId)
      }
    }
  }
})



function checkScript(url) {
  let scripts = document.getElementsByTagName('script');

  for (var i = scripts.length; i--;) {
    if (scripts[i].src == url) return true;
  }
}

function getUniqTemplateIdList(templateIds: any, myWishes: any) {

  const wishedTemplateIds = _map(myWishes, w => w?.templateId)
  const mergeList = _concat(templateIds, wishedTemplateIds)
  const uniqList = _unique(mergeList)

  return uniqList
}